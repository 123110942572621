import React from 'react';
import { observer } from 'mobx-react';
import PurchasingInstructionsModel from './model';
import { Button, Col, Row, Card, Space, Form, Modal, Pagination, Select, Cascader, Empty } from 'antd';
import styles from './index.less';
import { EditOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';

@observer
export default class PurchasingInstructions extends React.Component {
  constructor(prop) {
    super(prop);
    this.store = new PurchasingInstructionsModel();
  }

  public declare store: PurchasingInstructionsModel;

  render() {
    const { formRef, getCategorySecond, onEdit, pageData, cityList, total, current, pageSize, onShowSizeChange, getSecondary, firsList, secondList, onSubmit, setModalOpen, modalOpen } = this.store;
    return (
      <div className={styles.page}>
        <div>
          <Button onClick={setModalOpen}>新增</Button>
        </div>
        {!pageData.length ? (
          <div className={styles.empty}>
            <Empty />
          </div>
        ) : undefined}
        {pageData.length ? (
          <Space
            size={22}
            className={styles.content}
          >
            {pageData?.map((_, i) => (
              <div
                key={_.id}
                className={styles.card}
              >
                <div>
                  <span>
                    {_.regionName}
                    {' > '}
                    {_.parentCategoryName}
                    {' > '}
                    {_.categoryName}
                  </span>
                  <div
                    onClick={() => {
                      onEdit(_);
                    }}
                  >
                    <EditOutlined className={styles.editOutlined} />
                  </div>
                </div>
                <div
                  className={styles.notice}
                  dangerouslySetInnerHTML={{ __html: _.notice }}
                />
              </div>
            ))}
          </Space>
        ) : undefined}
        <Pagination
          align="end"
          pageSizeOptions={[50, 100, 150, 200]}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          current={current}
          pageSize={pageSize}
          total={total}
        />
        <Form
          scrollToFirstError
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 12 }}
          ref={formRef}
        >
          <Modal
            open={modalOpen}
            onCancel={setModalOpen}
            onOk={onSubmit}
            width={800}
          >
            <div className={styles.modalContent}>
              <Row className={styles.modalHeader}>
                <Col span={8}>
                  <Form.Item
                    label="区域"
                    name="regionId"
                    rules={[{ required: true }]}
                  >
                    <Cascader
                      fieldNames={{
                        label: 'name',
                        value: 'id',
                        children: 'childrenList',
                      }}
                      options={cityList}
                      className={styles.select}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="一级类别"
                    name="parentCategoryId"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(value) => {
                        getSecondary(value);
                      }}
                      options={firsList}
                      className={styles.select}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="二级类别"
                    name="categoryId"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={secondList}
                      className={styles.select}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                noStyle
                name="notice"
                // rules={[{ required: true }]}
              >
                <BraftEditor
                  controls={[
                    'undo',
                    'redo',
                    'blockquote',
                    'bold',
                    // 'code',
                    'clear',
                    // 'emoji',
                    'font-family',
                    'font-size',
                    'headings',
                    'italic',
                    'letter-spacing',
                    'line-height',
                    // 'link',
                    // 'list-ol',
                    // 'list-ul',
                    'remove-styles',
                    'separator',
                    'strike-through',
                    'text-align',
                    'text-color',
                    'text-indent',
                    'underline',
                  ]}
                />
              </Form.Item>
            </div>
          </Modal>
        </Form>
      </div>
    );
  }
}
