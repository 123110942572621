import React from 'react';
import type { FormInstance } from 'antd';
import { message } from 'antd';
import { action, observable } from 'mobx';
import type { BaseData } from '../../utils';
import { request } from '../../utils';
import BraftEditor from 'braft-editor';

export default class PurchasingInstructionsModel {
  constructor() {
    this.getPageList();
    this.getCategoryFirst();
    this.getCityList();
  }

  @observable public modalOpen = false;

  @observable public firsList = [];

  @observable public secondList = [];

  @observable public cityList = [];

  public editId: string;

  @action
  public onEdit = async (_) => {
    await this.getCategorySecond(_.parentCategoryId);
    this.formRef?.current?.setFieldsValue({
      notice: BraftEditor.createEditorState(_.notice || ''),
      parentCategoryId: _.parentCategoryId,
      categoryId: _.categoryId,
      regionId: [_.parentRegionId, _.regionId],
    });
    this.editId = _.id;
    this.setModalOpen();
  };

  /**
   * 获取城市
   */
  @action
  public getCityList = async () => {
    const req = await request<{ data: any[] }>({
      method: 'GET',
      url: '/quan/sys_area/province/city/list',
    });
    this.cityList = req?.data || [];
  };

  /**
   * 获取一级类别
   */
  @action
  public getCategoryFirst = async () => {
    const req = await request<{ data: any[] }>({
      method: 'GET',
      url: '/quan/biz/category/list/type',
      params: { categoryType: 0 },
    });
    this.firsList = this.toOptions(req?.data);
  };

  /**
   * 根据一级类别获取二级类别
   * @param value
   */
  @action
  public getCategorySecond = async (value: string) => {
    const req = await request<{ data: any[] }>({
      method: 'POST',
      url: '/quan/biz/category/parent',
      data: { ids: `${value}` },
    });
    this.secondList = this.toOptions(req?.data);
  };

  @action
  public setModalOpen = () => {
    this.modalOpen = !this.modalOpen;
    if (!this.modalOpen) {
      this.formRef?.current?.resetFields();
      this.editId = undefined;
    }
  };

  @action
  public onSubmit = () => {
    this.formRef?.current?.validateFields().then(async (data) => {
      const params = { ...data };
      if (!data?.notice?.toHTML()) {
        message.warning('请填写具体内容！');
        return;
      }
      if (params.regionId) {
        const [parentRegionId, regionId] = params.regionId;
        params.parentRegionId = parentRegionId;
        params.regionId = regionId;
      }
      const req = await request<{ msg: string }>({
        method: 'POST',
        url: this.editId ? '/quan/biz/buyer/notice/edit' : '/quan/biz/buyer/notice/add',
        data: {
          ...params,
          notice: data?.notice?.toHTML(),
          id: this.editId,
        },
      });
      message.success(req?.msg);
      this.getPageList();
      this.setModalOpen();
    });
  };

  @action
  public getSecondary = (value) => {
    this.formRef?.current?.setFieldValue('categoryId', undefined);
    this.getCategorySecond(value);
  };

  @observable public current = 1;

  @observable public pageSize = 50;

  @observable public total = 0;

  @observable public pageData = [];

  @action
  public onShowSizeChange = (current, size) => {
    this.current = current;
    this.pageSize = size;
    this.getPageList();
  };

  @action
  public getPageList = async () => {
    const req = await request<BaseData<{ list: any[]; total: number }>>({
      method: 'POST',
      url: '/quan/biz/buyer/notice/list/page',
      data: {
        pageNum: this.current,
        pageSize: this.pageSize,
      },
    });
    this.total = req?.data?.total || 0;
    this.pageData = req?.data?.list || [];
  };

  public formRef = React.createRef<FormInstance>();

  public toOptions = (data: any[]): Array<{ label: string; value: string }> =>
    (data || [])?.map((item: any) => ({
      label: item.categoryName,
      value: item.id,
    }));
}
